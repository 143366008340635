import { FunctionComponent, useContext, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { LangContext } from 'contexts/LanguageContext';
import DocsAssistProvider from 'contexts/DocsAssistContext';
import { DocsAssistDataProvider } from 'contexts/DocsAssistDataContext';

import {
  ApiPage,
  GetStartedPage,
  HomePage,
  PlatformPage,
  ReleasesPage,
  UiPage,
} from 'pages';

const Router: FunctionComponent = () => {
  const { lang } = useContext(LangContext);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes(`/${lang}/glossary`)) {
      window.location.href = `${process.env.PUBLIC_URL}/${lang}/docs/more-info/glossary/index.html`;
    }
  }, [location.pathname, lang]);

  return (
    <DocsAssistProvider>
      <DocsAssistDataProvider>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/:lang" element={<HomePage />} />
          <Route path="/:lang/get-started" element={<GetStartedPage />} />
          <Route path="/:lang/ui" element={<UiPage />} />
          <Route path="/:lang/api" element={<ApiPage />} />
          <Route path="/:lang/platform" element={<PlatformPage />} />
          <Route path="/:lang/release" element={<ReleasesPage />} />
        </Routes>
      </DocsAssistDataProvider>
    </DocsAssistProvider>
  );
};

export default Router;
