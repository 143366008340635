import { FunctionComponent } from 'react';
import { Highlight } from 'react-instantsearch-dom';
import { Badge } from '@datarobot/design-system/badge';
import classNames from 'classnames';
import Hit from 'interfaces/hit';
import ItemIcon from './ItemIcon';
import { getCategoryName } from 'components/search/Filter';

import './search-item.scss';

const SearchItem: FunctionComponent<{
  hit: Hit;
  onSelect: any;
  selectedHit: Hit | null;
}> = ({
  hit,
  onSelect,
  selectedHit,
}: {
  hit: Hit;
  onSelect: any;
  selectedHit: Hit | null;
}) => (
  <li>
    <a
      className={classNames('hits-item', {
        'selected-item': selectedHit && selectedHit.objectID === hit.objectID,
      })}
      href={`${hit.url}#${hit.anchor}`}
      onMouseOver={onSelect}
      onFocus={onSelect}
    >
      <div className="item-description">
        <ItemIcon category={hit.url && hit.url.split('/')[3]} />
        <div>
          <div>
            <Highlight className="h4" attribute="title" hit={hit} />
          </div>
          <div className="margin-top-2 section-name">
            <Highlight className="action-item" attribute="section" hit={hit} />
          </div>
          <div
            className="margin-top-4 preview"
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html: hit._highlightResult.content.value,
            }}
          />
        </div>
      </div>
      <Badge info>{getCategoryName(hit.itemType)}</Badge>
    </a>
  </li>
);

export default SearchItem;
