import { FunctionComponent } from 'react';
import { t } from 'ttag';
import { LanguageSwitcher } from 'components/language-switcher';
import { ReactComponent as RobotIcon } from 'assets/images/robot.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { DROPDOWN_MENU_PLACEMENT_TYPES } from '@datarobot/design-system/dropdown-menu';
import './footer.scss';

type LanguageProps = {
  key: string;
  title: string;
  iconClass: string;
  iconStyle: string;
};

type FooterProps = {
  lang: string;
  switchLang: Function;
};

const currentYear = new Date().getFullYear();

const Footer: FunctionComponent<FooterProps> = ({
  switchLang,
  lang,
}: FooterProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  function updateLanguage(option: LanguageProps): void {
    const url = location.pathname.split('/').slice(2).join('/');
    switchLang(option.key);
    navigate(`/${option.key}/${url}`);
  }

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="actions-content">
          <div className="actions">
            <RobotIcon className="robot-icon" />
            <p className="caption">{t`© ${currentYear} DataRobot, Inc.`}</p>
            <ul className="action-button">
              <li>
                <a
                  href="https://www.datarobot.com/legal/"
                  className="white-button caption"
                  test-id="legal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t`Legal`}
                </a>
              </li>
              <li>
                <a
                  href="https://www.datarobot.com/privacy/"
                  className="white-button caption"
                  test-id="privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t`Privacy`}
                </a>
              </li>
              <li>
                <a
                  href="https://www.datarobot.com/trust-package/"
                  className="white-button caption"
                  test-id="trust"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t`Trust`}
                </a>
              </li>
              <li>
                <a
                  href="https://status.datarobot.com/"
                  className="white-button caption"
                  test-id="status"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t`Status`}
                </a>
              </li>
            </ul>
          </div>
          <LanguageSwitcher
            selectedLanguage={lang}
            onUpdateLanguage={(option: LanguageProps) => updateLanguage(option)}
            placement={DROPDOWN_MENU_PLACEMENT_TYPES.BOTTOM}
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
