import { FunctionComponent, SVGProps, ReactNode } from 'react';
import { ReactComponent as ExternalLinkIcon } from 'assets/images/external_link.svg';

import './card.scss';

type CardProps = {
  icon: SVGProps<SVGSVGElement>;
  title: string;
  description: string | ReactNode;
  externalIcon?: boolean;
};
const Card: FunctionComponent<CardProps> = ({
  icon,
  title,
  description,
  externalIcon,
}: CardProps) => (
  <div className="card-wrapper">
    <div>{icon}</div>
    <div className="h4">
      {title}
      {externalIcon && <ExternalLinkIcon className="margin-left-2" />}
    </div>
    <div className="caption">{description}</div>
  </div>
);

export default Card;

Card.defaultProps = {
  externalIcon: false,
};
