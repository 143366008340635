import {
  FunctionComponent,
  useState,
  useEffect,
  useRef,
  SyntheticEvent,
} from 'react';
import { t } from 'ttag';
import { Button, ACCENT_TYPES } from '@datarobot/design-system/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import Hit from 'interfaces/hit';
import StateResults from './StateResults';

import './autocomplete.scss';

type AutoCompleteProps = {
  itemType: string;
  hits: Array<Hit>;
  currentRefinement: string;
  refine: Function;
  onModalClose: any;
  onFilter: Function;
  searchValue: string;
  onChange: Function;
};

let timerId: any = 0;

const Autocomplete: FunctionComponent<any> = ({
  itemType,
  hits,
  currentRefinement,
  refine,
  onFilter,
  searchValue,
  onChange,
}: AutoCompleteProps) => {
  const [selectedHit, setHit] = useState<Hit | null>(null);

  const previewRef = useRef<any>(null);

  useEffect(() => {
    if (hits.length > 0) {
      setHit(hits[0]);
    } else {
      setHit(null);
    }
  }, [hits]);

  useEffect(() => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      refine(searchValue);
    }, 300);
  }, [searchValue]);

  function onInputClear(): void {
    onChange('');
    refine(null);
  }

  function onInputChange(event: SyntheticEvent<HTMLInputElement>): void {
    const { value } = event.currentTarget;
    onChange(value);
    clearTimeout(timerId);

    timerId = setTimeout(() => {
      refine(value);
    }, 300);

    if (value === '') {
      onFilter(null);
    }
  }

  function onSubmit(event: SyntheticEvent<HTMLFormElement>): void {
    event.preventDefault();
  }

  function onSelectHit(hit: Hit): void {
    setHit(hit);
    if (previewRef.current) {
      previewRef.current.scrollIntoView({ block: 'start' });
    }
  }

  return (
    <>
      <div className="search-box">
        <FontAwesomeIcon size="lg" icon={faSearch} aria-hidden="true" />
        <form className="search-form" onSubmit={onSubmit}>
          <input
            autoFocus
            autoComplete="off"
            id="modal-search-input"
            className="search-input subtitle"
            type="search"
            value={searchValue}
            onChange={onInputChange}
            test-id="algolia-search-input"
          />
        </form>
        <div className="action-buttons">
          <Button
            accentType={ACCENT_TYPES.GHOST}
            onClick={() => onInputClear()}
            leftIcon={faXmark}
            testId="clear-action-button"
          >
            {t`Clear`}
          </Button>
        </div>
      </div>
      <StateResults
        hits={hits}
        itemType={itemType}
        onFilter={onFilter}
        onSelectHit={(hit: Hit) => onSelectHit(hit)}
        selectedHit={selectedHit}
        previewRef={previewRef}
        currentRefinement={currentRefinement}
        refine={refine}
      />
    </>
  );
};

export default Autocomplete;
