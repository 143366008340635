import { FunctionComponent, Fragment, forwardRef, useEffect } from 'react';
import { Highlight } from 'react-instantsearch-dom';
import Hit from 'interfaces/hit';
import { t } from 'ttag';

import './search-preview.scss';

const renderToc = (content: string[], section: string) => (
  <ul>
    {content.map((item, index) =>
      Array.isArray(item) ? (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`${item}-${index}`}>{renderToc(item, section)}</Fragment>
      ) : (
        <li
          // eslint-disable-next-line react/no-array-index-key
          key={`${item}-${index}`}
          className={`${item === section ? 'toc-highlighted' : ''}`}
        >
          {item}
        </li>
      )
    )}
  </ul>
);

const SearchPreview: FunctionComponent<{ hit: Hit; ref: any }> = forwardRef(
  (
    {
      hit,
    }: {
      hit: Hit;
    },
    ref: any
  ) => {
    useEffect(() => {
      let highlighted = document.querySelector('.toc-highlighted');
      while (highlighted?.parentNode?.nodeName.toLowerCase() === 'ul') {
        highlighted = highlighted?.parentElement;
        highlighted?.classList.add('contain');
        const prevElement = highlighted?.previousElementSibling;
        if (prevElement?.nodeName.toLowerCase() === 'li') {
          prevElement.classList.add('contain');
        }
      }
      highlighted
        ?.querySelectorAll(':not(.contain):not(.toc-highlighted)')
        .forEach((el) => el.classList.add('toc-hidden'));

      const details = document.querySelectorAll('details.tip');
      if (details.length > 0) {
        details.forEach((item) => {
          item.setAttribute('open', 'open');
        });
      }
    }, [hit]);

    return (
      <div className="search-preview">
        <div className="preview-content" ref={ref}>
          {hit.breadcrumbs && (
            <div className="breadcrumbs">
              {hit.breadcrumbs.slice(0, -1).map((title, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={`${title}-${i}`}>
                  <p className="paragraph-regular">{title}</p>
                  {i !== hit.breadcrumbs.length - 1 && (
                    <span className="delim">/</span>
                  )}
                </Fragment>
              ))}
            </div>
          )}
          <h2>
            <Highlight className="h2" attribute="title" hit={hit} />
          </h2>
          {hit.toc && (
            <div className="caption">
              <p>
                <b>{t`Table of contents`}</b>
              </p>
              {renderToc(hit.toc, hit.section)}
              <p>...</p>
            </div>
          )}
          <div
            className="paragraph-regular hit-content"
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html: hit._highlightResult.content.value,
            }}
          />
        </div>
      </div>
    );
  }
);

export default SearchPreview;
