import { FunctionComponent, useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from 'routes';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { LastUpdated } from 'components/last-updated';
import { LangContext } from 'contexts/LanguageContext';
import SnowplowListener from './SnowplowListener';

const App: FunctionComponent = () => {
  const { lang, switchLang } = useContext(LangContext);
  return (
    <BrowserRouter>
      <SnowplowListener>
        <Header />
        <main>
          <Router />
        </main>
        <LastUpdated />
        <Footer lang={lang} switchLang={switchLang} />
      </SnowplowListener>
    </BrowserRouter>
  );
};

export default App;
