import { FunctionComponent, SVGProps, ReactNode, useContext } from 'react';
import classNames from 'classnames';
import { t } from 'ttag';
import { Link } from 'react-router-dom';
import { Card } from 'components/card';
import { Search } from 'components/search';
import { DocsAssistContext } from 'contexts/DocsAssistContext';

import './page-layout.scss';

type CardProps = {
  key: string;
  icon: SVGProps<SVGSVGElement>;
  title: string;
  className?: string;
  workbench?: boolean;
  description: string | ReactNode;
  externalIcon?: boolean;
  route?: string;
  link?: string;
};

type PageProps = {
  title: string;
  subtitle: string | ReactNode;
  cardsContent: Array<CardProps>;
  className?: string;
  cardsWidth?: string;
};

const PageLayout: FunctionComponent<PageProps> = ({
  title,
  subtitle,
  cardsContent,
  className,
  cardsWidth,
}: PageProps) => {
  const docsAssistEnabled = useContext(DocsAssistContext);

  return (
    <div className={classNames(className, 'page-content')}>
      <h1 className="title">{title}</h1>
      <p className="subtitle">{subtitle}</p>
      <div
        className={classNames(cardsWidth, 'search-container', {
          'docs-assist-search-container': docsAssistEnabled,
        })}
      >
        {docsAssistEnabled ? (
          <>
            <p className="subtitle search-title">{t`Have a question?`}</p>
            <Search />
          </>
        ) : (
          <Search />
        )}
      </div>

      <ul
        className={classNames(cardsWidth, 'cards-container', {
          'docs-assist-cards': docsAssistEnabled,
        })}
      >
        {cardsContent.map((item: CardProps) => {
          const externalProps = item.externalIcon
            ? { target: '_blank', rel: 'noopener noreferrer' }
            : {};
          const isWorkbench = item.workbench;
          const listItemClass = isWorkbench ? 'workbench-card' : 'card-link';
          return (
            <li key={item.key} className={listItemClass}>
              {item.route ? (
                <Link to={item.route} test-id={`${item.key}-link-url`}>
                  <Card
                    key={item.key}
                    icon={item.icon}
                    title={item.title}
                    description={item.description}
                    externalIcon={item.externalIcon}
                  />
                </Link>
              ) : (
                // eslint-disable-next-line
                <a href={item.link} test-id={`${item.key}-link-url`} {...externalProps} className={item.className}>
                  <Card
                    key={item.key}
                    icon={item.icon}
                    title={item.title}
                    description={item.description}
                    externalIcon={item.externalIcon}
                  />
                </a>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PageLayout;

PageLayout.defaultProps = {
  className: '',
  cardsWidth: 'large',
};
