import { FunctionComponent, useContext } from 'react';
import { LangContext } from 'contexts/LanguageContext';
import { t } from 'ttag';
import preval from 'preval.macro';

import './last-updated.scss';

type LocaleProps = { [key: string]: string };

const getLastUpdatedDate = (lang: string) => {
  const locale: LocaleProps = {
    ja: 'ja-JP',
    en: 'en-US',
  };

  const buildDate = preval`module.exports = new Date();`;
  return new Date(buildDate).toLocaleString(locale[lang], {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

const LastUpdated: FunctionComponent = () => {
  const { lang } = useContext(LangContext);
  return (
    <div className="lastupdated-container">
      <div className="lastupdated-wrapper caption">
        <div className="lastupdated-content">
          <span className="date-lead-in">{t`Updated`}</span>
          <span className="date-text">{getLastUpdatedDate(lang)}</span>
        </div>
      </div>
    </div>
  );
};

export default LastUpdated;
