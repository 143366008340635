import { useState, FunctionComponent } from 'react';
import parse from 'html-react-parser';
import { t } from 'ttag';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { RadioGroup } from '@datarobot/design-system/radio-group';
import { Button, ACCENT_TYPES } from '@datarobot/design-system/button';
import { TOOLTIP_PLACEMENT_TYPES } from '@datarobot/design-system/tooltip';
import { Input } from '@datarobot/design-system/input';
import {
  CodeEditor,
  CODE_EDITOR_MODES,
} from '@datarobot/design-system/code-editor';
import {
  CollapsiblePanel,
  COLLAPSIBLE_PANEL_ACCENT_TYPES,
} from '@datarobot/design-system/collapsible-panel';
import { useDocsAssistData } from 'contexts/DocsAssistDataContext';
import { getBotResults, sendBotFeedback } from 'services/docs-assist';
import { DocsAssistConfidence } from './DocsAssistConfidence';
import { ReactComponent as NoAnswerIcon } from 'assets/images/docsassist/no-answer.svg';

import './docs-assist.scss';

const REPOSITORY_NAME = {
  DATAROBOT_DOCS: 'datarobot_docs',
  PUBLIC_API_CLIENT: 'public_api_client',
  R_API_CLIENT: 'rsdk',
};

const sourceOptions = [
  {
    id: 'All',
    label: t`All`,
  },
  {
    id: REPOSITORY_NAME.DATAROBOT_DOCS,
    label: t`UI docs`,
  },
  {
    id: REPOSITORY_NAME.PUBLIC_API_CLIENT,
    label: t`Python docs`,
  },
];

const gradingOptions = [
  {
    label: t`Correct`,
    value: 'correct',
    desc: t`Correct: The response sufficiently answers the question.`,
  },
  {
    label: t`Incorrect`,
    value: 'incorrect',
    desc: t`Incorrect: The response "hallucinates" or is the wrong answer to the question.`,
  },
  {
    label: t`Incomplete`,
    value: 'incomplete',
    desc: t`Incomplete: The response does not fully answer the question.`,
  },
  {
    label: t`Digress`,
    value: 'digress',
    desc: t`Digress: The response includes irrelevant information or is not concise.`,
  },
  {
    label: t`No Answer`,
    value: 'no_answer',
    desc: t`No Answer: The response claims it cannot or will not answer the question.`,
  },
];

const GRADES = {
  CORRECT: 'correct',
  INCORRECT: 'incorrect',
  INCOMPLETE: 'incomplete',
  DIGRESS: 'digress',
  NO_ANSWER: 'no_answer',
};

// cleanup if not needed in future
const formatAnswer = (text: string) => text;
const formatCitations = (arr: any[]) =>
  arr.filter((citation) => !citation?.metadata?.exclude);

export type DocsAssistProps = {
  searchValue?: string;
  onChange?: (value: string) => void;
  onClearSearch?: () => void;
};

const DocsAssist: FunctionComponent<DocsAssistProps> = ({
  searchValue = '',
  onChange = () => {},
  onClearSearch = () => {},
}: DocsAssistProps) => {
  const docsAssistData = useDocsAssistData();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isGrading, setGrading] = useState<boolean>(false);
  const [isBlocked, setBlocked] = useState<boolean>(false);
  const { source, hasGraded } = docsAssistData;
  const { answer, citations, confidence, associationId } = docsAssistData.data;
  const gradeNoAnswer = confidence.Grade === GRADES.NO_ANSWER;

  async function getResults(sourceRequest = source) {
    setLoading(true);
    setBlocked(false);
    docsAssistData.setGraded(false);

    try {
      const { data } = await getBotResults(sourceRequest, searchValue);
      if (data.blocked) {
        // Do not cache blocked responses
        setBlocked(true);
      } else {
        docsAssistData.setData({
          answer: formatAnswer(data?.response?.answer),
          associationId: data?.associationid,
          confidence: data?.confidence,
          citations: formatCitations(data?.response?.references),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function onSubmit() {
    if (!searchValue) return;
    docsAssistData.clearData();
    await getResults();
  }

  function onClear() {
    docsAssistData.clearData();
    onClearSearch();
  }

  async function submitGrade(grade: string) {
    setGrading(true);
    try {
      await sendBotFeedback(associationId, grade);
      docsAssistData.setGraded(true);
    } catch (err) {
      console.error(err);
    }
    setGrading(false);
  }

  function handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      onSubmit();
    }
  }

  const CollapsiblePanelTypeStandalone = (stuff: any) => {
    const [expanded, setExpanded] = useState(false);
    const citation = stuff.stuff;
    const repo = citation.metadata.repo;
    const link = citation.metadata.href || citation.metadata.source;
    const filePath = citation.metadata.file_path;
    const isDataRobotDocsRepository = repo === REPOSITORY_NAME.DATAROBOT_DOCS;
    const isRApiClientRepository = repo === REPOSITORY_NAME.R_API_CLIENT;
    const isPublicApiClientRepository =
      repo === REPOSITORY_NAME.PUBLIC_API_CLIENT;

    let content;

    if (isDataRobotDocsRepository && !citation.metadata.error) {
      content = (
        <iframe
          src={link.replace(
            'https://docs.datarobot.com',
            window.location.origin
          )}
          title={citation.metadata['page-title']}
        ></iframe>
      );
    } else if (isDataRobotDocsRepository && citation.metadata.error) {
      content = <Markdown>{citation.page_content}</Markdown>;
    } else if (isRApiClientRepository) {
      if (filePath.endsWith('.html')) {
        content = parse(citation.page_content);
      }
      if (filePath.endsWith('.R')) {
        content = (
          <CodeEditor
            options={{
              mode: CODE_EDITOR_MODES.R,
              readOnly: 'nocursor',
            }}
            onChange={() => {}}
            value={citation.page_content}
          />
        );
      }
    } else {
      content = (
        <Markdown className="line-break">{citation.page_content}</Markdown>
      );
    }

    let header;
    const pageTitle = citation?.metadata?.['page-title'];
    const pageDescription = citation?.metadata?.['page-description'];
    const openDocumentation = (
      <span className="open-documentation-link">
        <a
          href={citation?.metadata?.source}
          target="_blank"
          rel="noreferrer"
        >{t`Open documentation`}</a>
        <FontAwesomeIcon icon={faExternalLinkAlt} className="padding-left-1" />
      </span>
    );

    if (pageTitle && pageDescription && !pageTitle.includes('edirect')) {
      header = (
        <div className="citation">
          <h3 className="citation-page-title">
            {t`UI docs:`} {pageTitle}
          </h3>
          <span>{pageDescription}</span>
          {openDocumentation}
        </div>
      );
    } else if (
      pageTitle &&
      !pageDescription &&
      !pageTitle.includes('edirect')
    ) {
      header = (
        <div className="citation">
          <h3 className="citation-page-title">
            {t`UI docs:`} {pageTitle}
          </h3>
          {openDocumentation}
        </div>
      );
    } else if (isPublicApiClientRepository) {
      header = (
        <div className="citation">
          <h3 className="citation-page-title">
            {t`API docs:`} {citation?.metadata?.file_path}
          </h3>
          {openDocumentation}
        </div>
      );
    } else {
      header = citation?.metadata?.file_path;
    }

    return (
      <CollapsiblePanel
        key={Math.floor(Math.random() * 1001)}
        onChange={setExpanded}
        expanded={expanded}
        header={header}
        headerTestId="collapsible-panel-header"
        accentType={COLLAPSIBLE_PANEL_ACCENT_TYPES.STANDALONE}
      >
        <div test-id="collapsible-panel-content">{content}</div>
      </CollapsiblePanel>
    );
  };

  const renderCitations = (cit: any[]) =>
    cit.map((data) => (
      <CollapsiblePanelTypeStandalone
        key={Math.floor(Math.random() * 1001)}
        stuff={data}
      />
    ));

  const guardMessage =
    t`You appear to be asking for information about a company that is not DataRobot and,
    unfortunately, DocsAssist cannot provide reliable information about another company's products.` +
    ' ' +
    t`If you mentioned another vendor because you are looking for information on integrations, see the DataRobot
    <a href="https://docs.datarobot.com/en/docs/api/accelerators/index.html" rel="noreferrer" target="_blank">AI Accelerators</a>.`;

  return (
    <>
      <div className="search-box-container">
        <div className="search-box">
          <Input
            id="modal-prompt-input"
            className="search-input subtitle"
            placeholder={t`Send a prompt`}
            value={searchValue}
            autoFocus={true}
            onChange={(e) => onChange(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
            autoComplete="off"
            test-id="search-input"
          />
          <div className="action-buttons">
            <Button
              accentType={ACCENT_TYPES.GHOST}
              onClick={onClear}
              ariaLabel={t`Clear`}
              leftIcon={faXmark}
              className="margin-right-2"
              testId="clear-action-button"
            >
              {t`Clear`}
            </Button>
            <Button
              onClick={onSubmit}
              isLoading={isLoading}
              accentType={ACCENT_TYPES.SECONDARY}
              testId="send-action-button"
            >
              {t`Send`}
            </Button>
          </div>
        </div>
        <RadioGroup
          testId="docs-assist-radiogroup"
          activeItemId={source}
          onSelectItem={async (sourceItem) => {
            docsAssistData.setSource(sourceItem);
            if (!!answer) {
              await getResults(sourceItem);
            }
          }}
          options={sourceOptions}
          className="data-sources"
        />
      </div>

      {!isLoading && !!answer && !isBlocked && !gradeNoAnswer && (
        <div className="search-results" test-id="search-results">
          <div>
            <div className="bot bot-answer" test-id="docs-assist-answer">
              <h2 className="bot-title">{t`Answer:`}</h2>
              <Markdown className="line-break" rehypePlugins={[rehypeRaw]}>
                {answer}
              </Markdown>
            </div>
            <div className="bot bot-confidence">
              <DocsAssistConfidence confidence={confidence} />
            </div>
            <div className="bot bot-citations">
              <h2 className="bot-title">{t`Citations:`}</h2>
              {renderCitations(citations)}
            </div>
            <div className="bot bot-feedback" test-id="docs-assist-feedback">
              <h3>{t`How would you rate this response?`}</h3>
              <div className="bot-feedback-action-bar">
                {!isGrading &&
                  gradingOptions.map((option, idx) => (
                    <Button
                      isDisabled={isGrading || hasGraded}
                      key={idx}
                      onClick={() => submitGrade(option.value)}
                      aria-label={option.label}
                      tooltipText={option.desc}
                      tooltipPlacement={TOOLTIP_PLACEMENT_TYPES.TOP}
                      testId="button-grade"
                    >
                      {option.label}
                    </Button>
                  ))}
                {isGrading && (
                  <Button
                    className="loading"
                    isDisabled={true}
                    isLoading={true}
                  />
                )}
              </div>
              {hasGraded && (
                <p
                  className="thanks"
                  test-id="grade-submitted-message"
                >{t`Thank you for rating!`}</p>
              )}
            </div>
          </div>
        </div>
      )}

      {!isLoading && !!answer && !isBlocked && gradeNoAnswer && (
        <div className="search-results">
          <div className="bot bot-no-answer" test-id="docs-assist-no-answer">
            <NoAnswerIcon className="no-answer-icon padding-bottom-4" />
            <p className="text">
              {t`Sorry, I couldn't retrieve any relevant content.`}
            </p>
            <p className="text">
              {t`If your question is related to using DataRobot, try adding more context.`}
            </p>
          </div>
        </div>
      )}

      {!isLoading && !answer && isBlocked && (
        <div className="search-results">
          <div
            className="bot bot-answer blocked"
            test-id="docs-assist-blocked-answer"
          >
            <p dangerouslySetInnerHTML={{ __html: guardMessage }} />
          </div>
        </div>
      )}
    </>
  );
};

export default DocsAssist;
