import { FunctionComponent } from 'react';
import { Stats } from 'react-instantsearch-dom';
import { Button, ACCENT_TYPES } from '@datarobot/design-system/button';
import { t, ngettext, msgid } from 'ttag';

import './filter.scss';

export function getCategoryName(itemType?: string): string | {} {
  const categories: { [category: string]: string } = {
    all: t`All`,
    platform: t`UI docs`,
    api: t`API docs`,
    tutorials: t`Tutorials`,
    notebooks: t`Notebooks`,
    glossary: t`Glossary`,
  };
  return itemType ? categories[itemType] : categories;
}

const Filter: FunctionComponent<{
  itemType: string | null;
  onSelect: Function;
}> = ({
  itemType,
  onSelect,
}: {
  itemType: string | null;
  onSelect: Function;
}) => (
  <>
    <div className="filter">
      <div className="paragraph-bold">{t`Search:`}</div>
      <div className="button-group">
        {Object.entries(getCategoryName()).map(([key, value]) => {
          const filterType = key === 'all' ? null : key;
          return (
            <Button
              key={key}
              accentType={
                itemType === filterType
                  ? ACCENT_TYPES.PRIMARY
                  : ACCENT_TYPES.COMMAND
              }
              className="action-item"
              onClick={() => {
                onSelect(filterType);
              }}
            >
              {value}
            </Button>
          );
        })}
      </div>
    </div>
    <Stats
      translations={{
        stats(nbHits) {
          return ngettext(
            msgid`${nbHits} result found`,
            `${nbHits} results found`,
            nbHits
          );
        },
      }}
    />
  </>
);

export default Filter;
