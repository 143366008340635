import { t } from 'ttag';
import { Confidence } from 'contexts/DocsAssistDataContext';

type DocsAssistConfidenceProps = {
  confidence: Confidence;
};

export const DocsAssistConfidence = ({
  confidence,
}: DocsAssistConfidenceProps) => (
  <>
    <h2>
      {t`Response Grade:`} <span className="cap">{confidence.Grade} </span>
    </h2>
    <p>
      {t`Correct: ${confidence.Correct}, Incomplete: ${confidence.Incomplete}, Digress: ${confidence.Digress},
Incorrect: ${confidence.Incorrect}, No Answer: ${confidence['No Answer']}`}
    </p>
  </>
);
