import { FunctionComponent } from 'react';
import { ReactComponent as NoResultsIcon } from 'assets/images/no-results.svg';
import { Button } from '@datarobot/design-system/button';
import { t } from 'ttag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import Filter from './Filter';
import { getCategoryName } from 'components/search/Filter';

import './no-results.scss';

type NoResultsProps = {
  itemType: string;
  onFilter: Function;
  refinement: string;
  searching: boolean;
};

const NoResults: FunctionComponent<NoResultsProps> = ({
  itemType,
  onFilter,
  refinement,
  searching,
}: NoResultsProps) => (
  <>
    <div className="search-items">
      <div className="horizontal-divider" />
      <Filter itemType={itemType} onSelect={onFilter} />
      {searching ? (
        <div className="loading">
          <FontAwesomeIcon
            spin
            size="2x"
            icon={faCircleNotch}
            color="#cfd8e2"
            aria-hidden="true"
          />
        </div>
      ) : (
        <>
          <div className="no-filter-results" test-id="algolia-no-results">
            {itemType ? (
              <div className="no-results">
                <NoResultsIcon />
                <div className="description">
                  <span className="paragraph-regular">{t`No results in `}</span>
                  <span className="paragraph-bold">
                    {getCategoryName(itemType)}
                  </span>
                  <span className="paragraph-regular">{t` tab`}</span>
                </div>
                <Button
                  className="show-all-button"
                  onClick={() => onFilter(null)}
                >
                  {t`Show all results`}
                </Button>
              </div>
            ) : (
              <div className="no-results">
                <NoResultsIcon />
                <div className="description">
                  <span className="paragraph-regular">{t`No results for `}</span>
                  <span className="paragraph-bold">
                    <b>{`“${refinement}“`}</b>
                  </span>
                  <span className="paragraph-regular">.</span>
                  <p className="paragraph-regular">{t`Please try another search.`}</p>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
    <div className="search-preview">
      <div className="no-filter-results-preview">
        <span className="paragraph-regular">{t`No result to preview.`}</span>
      </div>
    </div>
  </>
);

export default NoResults;
