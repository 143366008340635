import { Fragment, FunctionComponent } from 'react';
import {
  connectStateResults,
  Pagination,
  ScrollTo,
} from 'react-instantsearch-dom';
import Hit from 'interfaces/hit';
import Filter from './Filter';
import SearchItem from './SearchItem';
import SearchPreview from './SearchPreview';
import NoResults from './NoResults';

type CustomStateResultsProps = {
  itemType: string;
  searching: boolean;
  hits: Array<Hit>;
  onFilter: Function;
  onSelectHit: Function;
  selectedHit: Hit;
  currentRefinement: string;
  previewRef: any;
};

const CustomStateResults: FunctionComponent<any> = ({
  itemType,
  searching,
  hits,
  onFilter,
  onSelectHit,
  selectedHit,
  currentRefinement,
  previewRef,
}: CustomStateResultsProps) => {
  if (hits.length === 0) {
    return (
      <div className="search-results">
        <NoResults
          refinement={currentRefinement}
          itemType={itemType}
          onFilter={onFilter}
          searching={searching}
        />
      </div>
    );
  }

  return (
    <div className="search-results">
      <div className="search-items">
        <div className="horizontal-divider" />
        <Filter itemType={itemType} onSelect={onFilter} />
        <ul className="search-items-list">
          <ScrollTo>
            {hits.map((hit) => (
              <Fragment key={hit.objectID}>
                <SearchItem
                  hit={hit}
                  key={hit.objectID}
                  onSelect={() => onSelectHit(hit)}
                  selectedHit={selectedHit}
                />
                <div className="horizontal-divider" />
              </Fragment>
            ))}
          </ScrollTo>
        </ul>
        <Pagination padding={2} showLast />
      </div>
      {selectedHit && <SearchPreview hit={selectedHit} ref={previewRef} />}
    </div>
  );
};

const StateResults = connectStateResults(CustomStateResults);

export default StateResults;
